<template>
  <div class="col-sm-4 form-group mb-4" :class="rootClassName">
    <label>{{ this.$i18n.t('form.CadCompanhiaTransporte.label') }}</label>
    <v-select v-if="!isView" name="nome" label="nome" :clearable="false" :searchable="true" v-model="selected" @search="fetchOptions" :options="optionList"
              @input="onSelect" :filter-by="filter" >
      <template slot="no-options" slot-scope="{searching, search}">
        <em v-if="!searching" style="opacity: 0.5;">
          Digite para buscar um armador
        </em>
        <em v-else style="opacity: 0.5;">
          Nenhum armador encontrado para {{ search }}
        </em>
      </template>
      <template slot="option" slot-scope="option">
        <div class="d-center">
          {{ option.nome }} {{ option.cpfCnpj ? ('(' + option.cpfCnpj + ')') : '' }}
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">
          {{ option.nome }} {{ option.cpfCnpj ? ('(' + option.cpfCnpj + ')') : '' }}
        </div>
      </template>
    </v-select>
    <div v-else-if="isView">
      {{ selected.nome }} {{ selected.cpfCnpj ? ('(' + selected.cpfCnpj + ')') : '' }}
    </div>
  </div>
</template>

<script>
import PersonService from '@/services/PersonService'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  inheritAttrs: false,
  name: 'ArmadorField',
  components: {
    vSelect
  },
  created () {
    let _this = this
    _this.id = 'armador_' + _this._uid
  },
  data () {
    return {
      optionList: [],
      id: null,
      timer: 0,
      selected: null
    }
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    isView: {
      type: Boolean,
      required: false,
      default: false
    },
    delay: {
      required: false,
      type: Number,
      default: 500
    },
    value: {
      required: true
    },
    rootClassName: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    /**
     * Triggered when search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    fetchOptions (search, loading) {
      let _this = this
      window.clearTimeout(this.timer)

      this.timer = window.setTimeout(function () {
        loading(true)
        let filters = {
          'searchTerm': search
        }

        PersonService.getTransportCompany(filters).then(res => {
          _this.optionList = res.data.data
          loading(false)
        })
      }, this.delay)
    },
    onSelect (val) {
      this.$emit('input', val)
    },
    filter (option, label, search) {
      let temp = search.toLowerCase()

      return (option.nome.toLowerCase().indexOf(temp) > -1 || (option.cpfCnpj && option.cpfCnpj.toLowerCase().indexOf(temp) > -1))
    }
  },
  watch: {
    value: function (newValue) {
      this.selected = newValue
    }
  }
}
</script>
